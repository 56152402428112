import React, { useEffect, useState } from 'react';
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker
} from 'react-google-maps';
import blueicon from '../assets/images/pin_black.svg'
import redicon from '../assets/images/pin_red.svg'
import './style.scss'

const styles = require('./style.json')
const GoogleMapSection =({loctiontab,handleLocationTab,mapdata})=>{
const [loader,setLoader] = useState(true)
const [mapobject,setMapobject] = useState(null)
useEffect(()=>{
     
},[loader])


const BasicMap = withScriptjs(withGoogleMap(() =>

    <GoogleMap
        defaultZoom={5}
         defaultCenter={{ lat: parseFloat(51.1657), lng: parseFloat(10.4515) }}       
        defaultOptions={{
           styles: styles,
           controlSize: 40,
        }}
       
        onTilesLoaded={()=>{setTimeout(() => {
            setLoader(false)
        }, 10);}}
    >  
        <Marker 
       defaultClickable={false}
        position={{ lat: 51.121260, lng: 17.031140 }} icon={loctiontab === 0 ? redicon : blueicon}/>
        <Marker 
         defaultClickable={false}
        position={{ lat: 51.057390, lng: 3.739350 }} icon={loctiontab === 1 ? redicon : blueicon}/>
        <Marker 
         defaultClickable={false}
        position={{ lat: 50.826740, lng: 4.400200 }} icon={loctiontab === 2 ? redicon : blueicon}/>
    </GoogleMap>
 
));

const genrateMap = ()=>{   
    let newMap = <BasicMap
    googleMapURL={'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyA4kwMOzmZhaSTDbAdKcP6qM6l8QfS7KsE'}
    loadingElement={<div style={{ height: 'inherit', width: 'inherit'}} />}
    containerElement={<div style={{ height: 'inherit' }} />}
    mapElement={<div className="map_main" style={{ height: 'inherit' }} />}
/>
if(mapobject === null){
setMapobject(newMap)}

return mapobject
}
    return (
        <React.Fragment>
             <div className="maploader" style={{display:loader ? `flex`:`none`}}>

             <svg className="l1map" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle className="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
        
         </div>
            {genrateMap()}
        </React.Fragment>
           
    )
}
export default GoogleMapSection;