import React,{useState,useEffect} from 'react'
import Layout from '../layouts'
import GoogleMapSection from '../components/google-map'
import Helmet from 'react-helmet'
const windowGlobal = typeof window !== 'undefined' && window

export const AboutPageTemplate = () => {
  const [loctiontab, setLoctiontab] = useState(0)
  useEffect(()=>{
  },[loctiontab])
  const handleLocationTab = (de) =>{
    setLoctiontab(de)
 
  }

  return (
    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
      <Helmet defer={false}>
        <title>About | JDSolutions</title>
        <meta property="og:title" content={`About | JDSolutions`} />
        <meta property="og:type" content="page" />
        {/* <meta property="og:image" content={colorimage} />   */}
      </Helmet>
      <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell about-section">
          <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
          <div className="row-fluid ">
            <div className="span12 widget-span widget-type-cell wrapper">
              <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
              <div className="row-fluid ">
              <div className="span6 widget-span widget-type-raw_html">
              <div className="cell-wrapper layout-widget-wrapper">
              <div className="cell-wrapper layout-widget-wrapper">
              <div className="location en-text">
              <div className="location_det"><h2>Hello.</h2> 
              <p className="bold-text">JDSolutions is a fast growing web agency with top-notch developers based in the silicon valley of Poland – Wroclaw. </p> <p>Today we offer various companies around the world an extension of their current team from within our office in Poland.</p> <p>We help out companies that are looking for one developer but we also provide mini teams.</p> <p>We are a professional nearshoring software house with an awesome motivated team all working from within the same office. Within our office we have different developers &amp; mini-teams who are 100% committed to a client from abroad.</p>
              </div>
              <div className="location_nav">
              <h6>See our locations</h6>

              <div className="location-blocks">
                    <div className="logation-grid">
                        <h4 className={`${loctiontab === 0 && 'active'}`} onClick={()=>handleLocationTab(0)}>Wroclaw</h4>
                        <address>Pomorska 55/9,<br/> 50-217 Wroclaw Poland<br/>
                            <span>Tel: <a href="tel:+48222129411">+48222129411</a></span>
                        </address>
                    </div>

                    <div className="logation-grid">
                        <h4 className={`${loctiontab === 1 && 'active'}`} onClick={()=>handleLocationTab(1)}>Ghent</h4>                        
                        <address>Oktrooiplein 1/210,<br/> 9000 Gent Belgium<br/>
                            <span>Tel: <a href="tel:+32485850102">+32485850102</a></span>
                        </address>
                    </div>

                    <div className="logation-grid">
                        <h4 className={`${loctiontab === 2 && 'active'}`} onClick={()=>handleLocationTab(2)}>Bruxelles</h4>
                        <address>Rue des Pères Blancs 4,<br/>1040 Bruxelles Belgium</address>
                    </div>
              </div>

              {/* <ul className="tabs_location">
              <li className={`${loctiontab === 0 && 'active'}`}>
                <a to="#" onClick={()=>handleLocationTab(0)}>Wroclaw</a>
                </li>
              <li className={`${loctiontab === 1 && 'active'}`}>
                <a to="#" onClick={()=>handleLocationTab(1)}>Ghent</a>
                </li>
              <li className={`${loctiontab === 2 && 'active'}`}>
                <a to="#" onClick={()=>handleLocationTab(2)}>Bruxelles</a>
                </li>
              </ul>
              <div className="location-adress">
              {loctiontab === 0 
              &&
              <address>Pomorska 55/9,<br/>
              50-217 Wroclaw Poland<br/>
              <span>Tel: <a href="tel:+48222129411">
                +48222129411</a></span>
                </address>}
                {loctiontab === 1 
              &&
              <address>Oktrooiplein 1/210,<br/> 9000 Gent Belgium<br/><span>Tel: <a href="tel:+32485850102">+32485850102</a></span></address>}
                {loctiontab === 2 
              &&
              <address>Rue des Pères Blancs 4,<br/>1040 Bruxelles Belgium</address>}
              </div> */}
              </div>
              </div>
                </div>
                </div>
                </div>
                <div className="span6 widget-span widget-type-raw_html ">
                  <div className="cell-wrapper layout-widget-wrapper">
                    <div className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
            
                      <div id="map" style={{position:"relative",opacity:1}} >
                      <div style={{height:'inherit',
                      position:'relative', 
                      opacity:loctiontab === 0 ? 1 : 0,
                      zIndex:loctiontab === 0 ? 1 : -1}}>
                      <GoogleMapSection loctiontab={0} handleLocationTab={handleLocationTab}/>
                      </div>
                      <div style={{position:'absolute',
                      top:0,
                      left:0,
                      width:`100%`,
                      height:`100%`,
                      opacity:loctiontab === 1 ? 1 : 0,
                      zIndex:loctiontab === 1 ? 1 : -1}}>
                      <GoogleMapSection loctiontab={1} handleLocationTab={handleLocationTab}/>
                      </div>
                      <div style={{position:'absolute',
                      top:0,
                      left:0,
                      width:`100%`,
                      height:`100%`,
                      opacity:loctiontab === 2 ? 1 : 0,
                      zIndex:loctiontab === 2 ? 1 : -1}}>
                      <GoogleMapSection loctiontab={2} handleLocationTab={handleLocationTab}/>
                      </div>
                      </div>
                    </div>
                 
                  
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const AboutPage = () => {
  const [page, setPage] = useState('about-page formal')

  return (
    <Layout page={page}>
      <AboutPageTemplate/>
    </Layout>
  )
}


export default AboutPage

